import React from 'react';

const Spring = () => {
  return (
    <div className="tile is-ancestor">
      <div className="m-6">
        <div className="my-4 text-center">
          <h2 className="font-headline my-2">WIP...</h2>
        </div>
      </div>
    </div>
  );
};

export default Spring;
